import './ChatWidget.css';

export default function ChatMessage({ initializeProps, message }) {

  if (message.direction === 'Out') { // It is a system / agent message
    return (
      <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1"><span
        className="relative flex shrink-0 overflow-hidden rounded-full w-10 h-10">
        <div className="rounded-full border p-1">
          <img className="w-full h-full" src={'https://img.icons8.com/?size=100&id=114253&format=png&color=000000'} />
        </div>
      </span>
        <div className="leading-relaxed"><span className="block font-bold text-gray-700">客服 </span>
          <div>{message.content}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1"><span
      className="relative flex shrink-0 overflow-hidden rounded-full w-10 h-10">
      <div className="rounded-full border p-1">
        <img className="w-full h-full" src={'https://img.icons8.com/?size=100&id=23242&format=png&color=000000'} />
      </div>
    </span>
      <div className="leading-relaxed"><span className="block font-bold text-gray-700">您 </span>
        <div>{message.content}</div>
      </div>
    </div>
  );

}