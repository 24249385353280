import './ChatWidget.css';
import ChatMessage from './ChatMessage';
import $ from 'jquery';

export default function ChatBox({ screenWidth, initializeProps, messages, submitSendoutMessage }) {
  const isMobile = screenWidth < 768;

  const onKeyDownDetection = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const val = $('#message-input').val();
      if (val && val.trim() && val.trim().length > 0) {
        onSubmitMessage(val);
      }
    }
  };

  const onClickSendButton = (e) => {
    e.preventDefault();
    const val = $('#message-input').val();
    if (val && val.trim() && val.trim().length > 0) {
      onSubmitMessage(val);
    }
  };

  const onSubmitMessage = (message) => {
    submitSendoutMessage(message);
  };

  return (
    <>
      {!isMobile && <>
        <div
          className="z-top fixed default-shadow bottom-[calc(7rem+1.5rem)] right-0 mr-12 bg-white p-6 rounded-lg border border-[#e5e7eb] w-[440px] h-[634px]">

          <div className="flex flex-col space-y-1.5 pb-6">
            <h2 className="font-semibold text-lg tracking-tight">{initializeProps.chatboxTitle}</h2>
            <p className="text-sm text-[#6b7280] leading-3">{initializeProps.poweredByText}</p>
          </div>


          <div id="chat-messages-container" className="pr-4 h-[474px]" style={{ minWidth: '100%', overflowX: 'hidden', overflowY: 'auto', /*display: 'table'*/ }}>
            <div>
              {
                messages.map((message) => {
                  return (<ChatMessage key={`msg-${message._id}`} message={message} />)
                })
              }
            </div>

          </div>
          <div className="flex items-center pt-0">
            <form onSubmit={(e) => e.preventDefault()} className="flex items-center justify-center w-full space-x-2">
              <input onKeyDown={onKeyDownDetection} id="message-input"
                className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                placeholder="請輸入..." />
              <button type="button" onClick={onClickSendButton}
                className="inline-flex text-xs items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-green-600 hover:bg-green-700 h-10 px-4 py-2">
                <svg viewBox="0 0 24 24" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.5003 12H5.41872M5.24634 12.7972L4.24158 15.7986C3.69128 17.4424 3.41613 18.2643 3.61359 18.7704C3.78506 19.21 4.15335 19.5432 4.6078 19.6701C5.13111 19.8161 5.92151 19.4604 7.50231 18.7491L17.6367 14.1886C19.1797 13.4942 19.9512 13.1471 20.1896 12.6648C20.3968 12.2458 20.3968 11.7541 20.1896 11.3351C19.9512 10.8529 19.1797 10.5057 17.6367 9.81135L7.48483 5.24303C5.90879 4.53382 5.12078 4.17921 4.59799 4.32468C4.14397 4.45101 3.77572 4.78336 3.60365 5.22209C3.40551 5.72728 3.67772 6.54741 4.22215 8.18767L5.24829 11.2793C5.34179 11.561 5.38855 11.7019 5.407 11.8459C5.42338 11.9738 5.42321 12.1032 5.40651 12.231C5.38768 12.375 5.34057 12.5157 5.24634 12.7972Z" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
              </button>
            </form>
          </div>

        </div>
      </>}
      {isMobile && <>
        <div
          className="fixed bottom-0 right-0 mr-0 bg-white p-6 border border-[#e5e7eb] w-full h-full">

          <div className="h-full flex flex-1 flex-col justify-start items-stretch">
            <div className="flex flex-col space-y-1.5 pb-6">
              <h2 className="font-semibold text-lg tracking-tight">{initializeProps.chatboxTitle}</h2>
              <p className="text-sm text-[#6b7280] leading-3">{initializeProps.poweredByText}</p>
            </div>


            <div id="chat-messages-container" className="pr-4 flex-1" style={{ minWidth: '100%', overflowX: 'hidden', overflowY: 'auto', /*display: 'table'*/ }}>
              <div>
                {
                  messages.map((message) => {
                    return (<ChatMessage key={`msg-${message._id}`} message={message} />)
                  })
                }
              </div>

            </div>
            <div className="flex items-center pt-0" style={{ marginBottom: 120 }}>
              <form onSubmit={(e) => e.preventDefault()} className="flex items-center justify-center w-full space-x-2">
                <input onKeyDown={onKeyDownDetection} id="message-input"
                  className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                  placeholder="請輸入..." />
                <button type="button" onClick={onClickSendButton}
                  className="inline-flex text-xs items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-green-600 hover:bg-green-700 h-10 px-4 py-2">
                  <svg viewBox="0 0 24 24" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.5003 12H5.41872M5.24634 12.7972L4.24158 15.7986C3.69128 17.4424 3.41613 18.2643 3.61359 18.7704C3.78506 19.21 4.15335 19.5432 4.6078 19.6701C5.13111 19.8161 5.92151 19.4604 7.50231 18.7491L17.6367 14.1886C19.1797 13.4942 19.9512 13.1471 20.1896 12.6648C20.3968 12.2458 20.3968 11.7541 20.1896 11.3351C19.9512 10.8529 19.1797 10.5057 17.6367 9.81135L7.48483 5.24303C5.90879 4.53382 5.12078 4.17921 4.59799 4.32468C4.14397 4.45101 3.77572 4.78336 3.60365 5.22209C3.40551 5.72728 3.67772 6.54741 4.22215 8.18767L5.24829 11.2793C5.34179 11.561 5.38855 11.7019 5.407 11.8459C5.42338 11.9738 5.42321 12.1032 5.40651 12.231C5.38768 12.375 5.34057 12.5157 5.24634 12.7972Z" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                </button>
              </form>
            </div>
          </div>

        </div>
      </>}
    </>
  );
}