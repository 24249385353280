import './ChatWidget.css';

export default function ChatButton({ hasNewMessage = false, chatOpenState, onOpenChat, onCloseChat, onHideChat, initializeProps }) {

  const handleTapButton = () => {
    if (chatOpenState === 'Closed') {
      onOpenChat();
    } else if (chatOpenState === 'Opened') {
      onHideChat();
    } else if (chatOpenState === 'Init') {
      onCloseChat();
    } else if (chatOpenState === 'InitLoading') {
      onCloseChat();
    }
  }

  return (
    <>
      <div className="fixed bottom-12 right-12 z-top">
        <button
          className="default-shadow inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 rounded-full w-16 h-16 bg-green-600 hover:bg-green-700 m-0 cursor-pointer bg-none p-0 normal-case leading-5 hover:text-gray-900"
          type="button" aria-haspopup="dialog" aria-expanded="false" data-state="closed" onClick={handleTapButton}>
          {
            chatOpenState === 'Closed' &&
            <>
              <svg xmlns=" http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                className="text-white block align-middle">
                <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z" className="">
                </path>
              </svg>
            </>
          }
          {
            (chatOpenState === 'Init' || chatOpenState === 'Opened' || chatOpenState === 'InitLoading') &&
            <>
              <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 21.32L21 3.32001" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3 3.32001L21 21.32" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </>
          }
        </button>

        {
          hasNewMessage &&
          <div className="absolute top-0 right-0">
            <span className="relative flex h-4 w-4">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-600 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-4 w-4 bg-orange-500"></span>
            </span>
          </div>
        }
      </div>
    </>
  );
}