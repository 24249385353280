import { useState, useEffect, useRef } from 'react';
import ChatButton from './ChatButton';
import ChatBox from './ChatBox';
import ChatInitBox from './ChatInitBox';
import ChatInitLoading from './ChatInitLoading';
import { io } from "socket.io-client";
import './ChatWidget.css';
import moment from 'moment';
import $ from 'jquery';
import { useRefState } from './lib/custom-state';
import { logEvt } from './lib/event-tracker.js';
import { checkIsExistingExternalUserId } from './api/chat.js';

export default function ChatWidget({ initializeProps }) {
  const [chatOpenState, setChatOpenState, chatOpenStateRef] = useRefState('Closed');
  const [chatSocket, setChatSocket] = useState(null);
  const [screenWidth, setScreenWidth] = useState(null);
  const [myProfile, setMyProfile, myProfileRef] = useRefState(null);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [messages, setMessages] = useState([{ _id: '0', direction: 'Out', msgNature: 'Greeting', msgType: 'text', content: '努力載入中...', deliveredAt: moment().local().fromNow() }]);


  const initChatBox = async (payload) => {
    setChatOpenState('InitLoading');
    payload.channel = initializeProps.channel;
    payload.metadata = JSON.stringify(initializeProps.metadata);
    payload.externalUserId = initializeProps.externalUserId;
    setMyProfile(payload);
    const socket = io(initializeProps.socketUrl, {
      path: '/chatws/',
      transports: ['websocket'],
      reconnectionDelayMax: 10000,
      query: payload,
    });
    socket.on('connect', () => {
      logEvt('ChatWidgetSocketConnected', { socketId: socket.id, externalUserId: payload.externalUserId });
    });
    socket.on('contactCreated', (data) => {
      setMyProfile((mp) => {
        mp.contactId = data.contactId;
        return mp;
      });
      logEvt('ChatWidgetSocketInitiated', { socketId: socket.id, contactId: data.contactId, externalUserId: payload.externalUserId });
      setChatOpenState('Opened');
    });
    socket.on('disconnect', () => {
      console.warn('[Socket] Socket is disconnected.');
      logEvt('ChatWidgetSocketDisconnected', { socketId: socket.id, contactId: myProfileRef.current?.contactId, externalUserId: payload.externalUserId });
    });
    socket.on('csMessage', handleCsMessage);
    socket.on('initFetchMessage', handleInitFetchMessage);
    setChatSocket(socket);
  };

  const handleInitFetchMessage = (data) => {
    const { messages } = data;
    setMessages(() => {
      messages.reverse();
      const msgs = messages;
      return msgs;
    });
    logEvt('ChatWidgetFetchInitMessagesCompleted', { contactId: myProfileRef.current?.contactId, messagesCount: messages.length });
  };

  const onresize = (event) => {
    console.log('[Evt] Screen is resized to', window.screen.width, 'px');
    setScreenWidth(window.screen.width);
  };

  useEffect(() => {
    console.log('[Evt] Initial screen size is', window.screen.width, 'px');
    setScreenWidth(window.screen.width);
    window.addEventListener("resize", onresize);
    return () => {
      window.removeEventListener("resize", onresize);
    };
  }, []);

  const handleCsMessage = (msg, callback) => {
    if (chatOpenStateRef.current !== 'Opened') {
      setHasNewMessage(true);
    }
    setMessages((mmm) => {
      const msgs = mmm.slice();
      msgs.push(msg);
      return msgs;
    });
    callback(new Date().toISOString());
  };

  useEffect(() => {
    $("#chat-messages-container").scrollTop(99999999);
  }, [messages]);

  const submitSendoutMessage = async (messageContent) => {
    const msg = {
      _id: null,
      direction: 'In',
      msgNature: 'Normal',
      msgType: 'text',
      content: messageContent,
      sentAt: new Date().toISOString(),
    };
    chatSocket.emit('customerMessage', { contact: myProfile.contactId, msg: msg }, ({ msgId }) => {
      const msgs = messages.slice();
      msg._id = msgId;
      msg.deliveredAt = new Date().toISOString();
      msgs.push(msg);
      setMessages(msgs);
    });
    $('#message-input').val('');
  };

  const onButtonOpenChat = async () => {
    if (myProfile) {
      setHasNewMessage(false);
      setChatOpenState('Opened');
      logEvt('ChatWidgetShow', { channelId: initializeProps.channel, contactId: myProfileRef.current?.contactId });
    } else {
      // Check existing
      const { data: isExist } = await checkIsExistingExternalUserId(initializeProps.apiUrl, initializeProps.channel, initializeProps.externalUserId);
      if (!isExist) {
        setChatOpenState('Init');
        logEvt('ChatWidgetInitTriggered', { channelId: initializeProps.channel });
      } else {
        initChatBox({});
        logEvt('ChatWidgetReEnterTriggered', { channelId: initializeProps.channel, externalUserId: initializeProps.externalUserId });
      }
    }
  };

  return (
    <div className="chat-widget twp">
      <ChatButton screenWidth={screenWidth} hasNewMessage={hasNewMessage} chatOpenState={chatOpenState} initializeProps={initializeProps} onOpenChat={onButtonOpenChat} onCloseChat={() => setChatOpenState('Closed')} onHideChat={() => {
        setChatOpenState('Closed');
        logEvt('ChatWidgetHide', { channelId: initializeProps.channel, contactId: myProfileRef.current?.contactId });
      }} />
      {
        chatOpenState === 'Opened' &&
        <ChatBox screenWidth={screenWidth} submitSendoutMessage={submitSendoutMessage} messages={messages} chatOpenState={chatOpenState} initializeProps={initializeProps} />
      }
      {
        chatOpenState === 'Init' &&
        <ChatInitBox screenWidth={screenWidth} chatOpenState={chatOpenState} initializeProps={initializeProps} onCancelChat={() => setChatOpenState('Closed')} onInitChat={initChatBox} />
      }
      {
        chatOpenState === 'InitLoading' &&
        <ChatInitLoading screenWidth={screenWidth} chatOpenState={chatOpenState} initializeProps={initializeProps} />
      }
    </div>
  );
}
