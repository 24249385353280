import './ChatWidget.css';
import $ from 'jquery';

export default function ChatInitBox({ screenWidth, initializeProps, onCancelChat, onInitChat }) {
  const isMobile = screenWidth < 768;

  const submitFormCreateChat = () => {
    const payload = {
      nickname: $('#nickname').val(),
      phone: $('#phone').val(),
      email: $('#email').val(),
    };
    onInitChat(payload);
  };

  return (
    <>
      {!isMobile &&
        <>
          <div
            className="z-top default-shadow fixed bottom-[calc(7rem+1.5rem)] right-0 mr-12 bg-white p-6 rounded-lg border border-[#e5e7eb] w-[440px] h-[634px]">

            <div className="flex flex-col space-y-1.5 pb-6">
              <h2 className="font-semibold text-lg tracking-tight">{initializeProps.chatboxTitle}</h2>
              <p className="text-sm text-[#6b7280] leading-3">{initializeProps.poweredByText}</p>
            </div>


            <div className="pr-4 h-[474px]" style={{ minWidth: '100%', display: 'table' }}>

              <form>
                <div className="border-b border-gray-900/10 pb-12">
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                    <div className="sm:col-span-6">
                      <label htmlFor="nickname" className="block text-sm font-medium leading-6 text-gray-900">
                        姓名
                      </label>
                      <div className="mt-2">
                        <input
                          id="nickname"
                          name="nickname"
                          type="nickname"
                          className="pl-2 pr-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-6">
                      <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                        電話號碼
                      </label>
                      <div className="mt-2">
                        <input
                          id="phone"
                          name="phone"
                          type="phone"
                          className="pl-2 pr-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>


                    <div className="sm:col-span-6">
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        電郵地址
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className="pl-2 pr-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => onCancelChat()}>
                    退出
                  </button>
                  <button
                    type="button" onClick={submitFormCreateChat}
                    className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    開始聊天
                  </button>
                </div>
              </form>

            </div>
          </div>
        </>}


      {isMobile && <>
        <div
          className="fixed bottom-0 right-0 mr-0 bg-white p-6 border border-[#e5e7eb] w-full h-full">

          <div className="flex flex-col space-y-1.5 pb-6">
            <h2 className="font-semibold text-lg tracking-tight">{initializeProps.chatboxTitle}</h2>
            <p className="text-sm text-[#6b7280] leading-3">{initializeProps.poweredByText}</p>
          </div>

          <div className="pr-4 h-[474px]" style={{ minWidth: '100%', display: 'table' }}>

            <form>
              <div className="border-b border-gray-900/10 pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                  <div className="sm:col-span-6">
                    <label htmlFor="nickname" className="block text-sm font-medium leading-6 text-gray-900">
                      姓名
                    </label>
                    <div className="mt-2">
                      <input
                        id="nickname"
                        name="nickname"
                        type="nickname"
                        className="pl-2 pr-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                      電話號碼
                    </label>
                    <div className="mt-2">
                      <input
                        id="phone"
                        name="phone"
                        type="phone"
                        className="pl-2 pr-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>


                  <div className="sm:col-span-6">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      電郵地址
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="pl-2 pr-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => onCancelChat()}>
                  退出
                </button>
                <button
                  type="button" onClick={submitFormCreateChat}
                  className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  開始聊天
                </button>
              </div>
            </form>

          </div>


        </div>
      </>}
    </>
  );
}