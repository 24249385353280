import axios from 'axios';

const axiosInst = axios.create({
  timeout: 5 * 60 * 1000,
});

axiosInst.interceptors.request.use(
  config => {
    // config.withCredentials = true;
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

axiosInst.interceptors.response.use(
  response => response.data,
  error => {
    let errResponseStatus = error && error.response && error.response.status
    console.error(error.response?.data || error.message);
    console.error(errResponseStatus);
    let errorMsg = error.message;
    // console.log("Error", error);
    if (error.response && error.response.data && error.response.data.error) {
      errorMsg = error.response.data.error;
    }
    return Promise.reject(error);
  }
);

export default axiosInst;
