const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

export const getGeoLocation = () => {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const crd = pos.coords;

      console.log("Your current position is:");
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);
      resolve(pos);
    }, (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      resolve(null)
    }, options);
  });
};

export const getDeviceBatteryStatus = async () => {
  try {
    const battery = await navigator.getBattery();
    return {
      level: Math.floor(battery.level * 100) + '%',
      charging: battery.charging,
    };
  } catch (ex) {
    console.error('Failed to obtain battery status', ex);
    return null;
  }
};

export function logEvt(event, payload) {
  if (navigator.creamEvt?.submit) {
    navigator.creamEvt.submit(event, payload);
  } else {
    console.log('CreamEvt is not enabled.');
  }
}
