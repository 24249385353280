import './ChatWidget.css';
import $ from 'jquery';

export default function ChatInitLoading({ screenWidth, initializeProps, onCancelChat, onInitChat }) {
  const isMobile = screenWidth < 768;

  const submitFormCreateChat = () => {
    const payload = {
      nickname: $('#nickname').val(),
      phone: $('#phone').val(),
      email: $('#email').val(),
    };
    onInitChat(payload);
  };

  return (
    <>
      {!isMobile &&
        <>
          <div
            className="z-top default-shadow fixed bottom-[calc(7rem+1.5rem)] right-0 mr-12 bg-white p-6 rounded-lg border border-[#e5e7eb] w-[440px] h-[634px]">

            <div className="flex flex-col space-y-1.5 pb-6">
              <h2 className="font-semibold text-lg tracking-tight">{initializeProps.chatboxTitle}</h2>
              <p className="text-sm text-[#6b7280] leading-3">{initializeProps.poweredByText}</p>
            </div>


            <div className="pr-4 h-[474px]" style={{ minWidth: '100%', display: 'table' }}>

              <div className="text-xs mt-10 text-gray-600 text-center">載入中...</div>

            </div>


          </div>
        </>}
      {isMobile &&
        <>
          <div
            className="fixed bottom-0 right-0 mr-0 bg-white p-6 border border-[#e5e7eb] w-full h-full">

            <div className="flex flex-col space-y-1.5 pb-6">
              <h2 className="font-semibold text-lg tracking-tight">{initializeProps.chatboxTitle}</h2>
              <p className="text-sm text-[#6b7280] leading-3">{initializeProps.poweredByText}</p>
            </div>


            <div className="pr-4 h-[474px]" style={{ minWidth: '100%', display: 'table' }}>

              <div className="text-xs mt-10 text-gray-600 text-center">載入中...</div>

            </div>


          </div>
        </>}
    </>
  );
}